import React, { useRef, useState } from "react";

export enum FormState {
  NOT_SUBMITTED,
  LOADING,
  SUCCESSFULLY_SUBMITTED,
  SUBMISSION_ERROR,
}

export interface FormController {
  startSubmission: () => void;
  isLoading: boolean;
  isFormSubmittable: boolean;
  isErrorMessageVisible: boolean;
  isSuccessMessageVisible: boolean;
  formAction: (formData: FormData) => Promise<any>;
  reset: () => void;
  resetMessagesOnly: () => void;
  formRef: React.RefObject<HTMLFormElement>;
  state: FormState;
}

export default function useFormController(props: {
  action?: (formData: FormData) => Promise<any>;
  onSuccess?: (response?: any) => void;
  onError?: (error: any) => void;
}): FormController {
  const [formState, setFormState] = useState<FormState>(
    FormState.NOT_SUBMITTED
  );
  const formRef = useRef<HTMLFormElement>(null);

  async function formAction(formData: FormData): Promise<any> {
    try {
      let response = await props.action?.(formData);
      setFormState(FormState.SUCCESSFULLY_SUBMITTED);
      props.onSuccess?.(response);
      return Promise.resolve(response);
    } catch (error: any) {
      setFormState(FormState.SUBMISSION_ERROR);
      props.onError?.(error);
      return Promise.reject(error);
    }
  }

  function startSubmission() {
    setFormState(FormState.LOADING);
  }

  function reset() {
    setFormState(FormState.NOT_SUBMITTED);
    formRef.current?.reset();
  }

  function resetMessagesOnly() {
    setFormState(FormState.NOT_SUBMITTED);
  }

  return {
    startSubmission,
    isLoading: formState === FormState.LOADING,
    isFormSubmittable:
      formState === FormState.NOT_SUBMITTED ||
      formState === FormState.SUBMISSION_ERROR,
    isErrorMessageVisible: formState === FormState.SUBMISSION_ERROR,
    isSuccessMessageVisible: formState === FormState.SUCCESSFULLY_SUBMITTED,
    formAction,
    reset,
    resetMessagesOnly,
    formRef,
    state: formState,
  };
}
