import Button from "@/components/common/Button";
import FormGroupInput from "@/components/common/forms/FormGroupInput";
import useFormController from "@/components/common/forms/useFormController";
import { AppIcons } from "@/components/global/icons/AppIcons";
import { TFunction } from "i18next";
import { useEffect } from "react";
import { Trans } from "react-i18next";
import { CompetitionListItem } from "../../common/CompetitionOverviewTable/CompetitionListItem";

type Props = {
  t: TFunction<string, undefined>;
  onSubmit?: () => void;
  searchAction: (formData: FormData) => Promise<CompetitionListItem[]>;
  onSearchResult: (result: CompetitionListItem[]) => void;
  defaultValues?: {
    from: string;
    till: string;
  };
};
export default function CompetitionSearchForm(props: Readonly<Props>) {
  const t = props.t;
  const formController = useFormController({
    action: (formData: FormData): Promise<CompetitionListItem[]> => {
      props.onSubmit?.();
      return props.searchAction(formData);
    },
    onSuccess: props.onSearchResult,
  });

  /**
   * Execute initial search
   */
  useEffect(() => {
    formController.formRef.current?.requestSubmit();
  }, [formController.formRef]);

  return (
    <form
      action={formController.formAction}
      onSubmit={formController.startSubmission}
      ref={formController.formRef}
    >
      <FormGroupInput inputId="competition-name" inputLabel={t("Name")} />
      <div className="row">
        <div className="col-md-6 col-12">
          <FormGroupInput
            inputId="from-date"
            inputLabel={t("FromDate")}
            inputType="date"
            value={props.defaultValues?.from}
          />
        </div>
        <div className="col-md-6 col-12">
          <FormGroupInput
            inputId="till-date"
            inputLabel={t("TillDate")}
            inputType="date"
            value={props.defaultValues?.till}
          />
        </div>
      </div>
      <FormGroupInput inputId="organization" inputLabel={t("Organization")} />
      <div className="form-group">
        <label htmlFor={"organization-filter"} className={"form-label"}>
          <Trans i18nKey="OrganizationFilter" t={t} />
        </label>
        <select
          id="organization-filter-type"
          name="organization-filter-type"
          className="form-select"
          disabled
          required
        >
          <option value="ConsiderOnlyThisOrganization">
            <Trans i18nKey="ConsiderOnlyThisOrganization" t={t} />
          </option>
          <option value="ConsiderSubOrganizations">
            <Trans i18nKey="ConsiderSubOrganizations" t={t} />
          </option>
          <option value="ConsiderHigherOrganizations">
            <Trans i18nKey="ConsiderHigherOrganizations" t={t} />
          </option>
        </select>
      </div>
      <div className="row justify-content-end">
        <div className="col-md-3 col-6 d-grid">
          <Button
            type="submit"
            id="search-competitions"
            showLoadingIndicator={formController.isLoading}
            disabled={formController.isLoading}
            icon={AppIcons.Search}
            style="subtle"
          >
            <Trans i18nKey="DoSearch" t={t} />
          </Button>
        </div>
        <div className="col-md-3 col-6 d-grid">
          <Button
            id="reset-search"
            type="reset"
            icon={AppIcons.Cancel}
            style="subtle"
            onClick={formController.reset}
            disabled={formController.isLoading}
          >
            <Trans i18nKey="Reset" t={t} />
          </Button>
        </div>
      </div>
    </form>
  );
}
