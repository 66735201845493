"use client";

import LoadingIndicator from "@/components/common/FullScreenOverlay/LoadingIndicator";
import useLocalizedDate from "@/components/global/hooks/useLocalizedDate";
import { useRouter } from "next/navigation";
import { CompetitionListItem } from "./CompetitionListItem";
import "./CompetitionOverviewTable.css";

type Messages = {
  noDataMessage: string;
  timespanColumnLabel: string;
  nameColumnLabel: string;
};

type Props = {
  lang : string
  competitions: CompetitionListItem[];
  isLoading?: boolean;
} & Messages;
export default function CompetitionTable(props: Readonly<Props>) {
  const { LocalizedDateRangeString } = useLocalizedDate(props.lang);
  const router = useRouter();

  return (
    <table id="competitions-table" className="table table-striped table-fixed">
      <thead style={{ display: "none" }}>
        <tr>
          <th scope="col">{props.timespanColumnLabel}</th>
          <th scope="col">{props.nameColumnLabel}</th>
        </tr>
      </thead>
      <tbody>
        {props.competitions.map((competition) => (
          <tr
            key={competition.id}
            onClick={() =>
              router.push(`/${props.lang}/competitions/${competition.acronym}`)
            }
            className="competition-row"
          >
            <td className="competition-timespan-column align-middle">
              {LocalizedDateRangeString(competition.from, competition.till)}
            </td>
            <td className="competition-column align-middle text-wrap">
              {competition.name}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={4} className="text-center">
            <span hidden={props.isLoading || props.competitions.length > 0}>
              {props.noDataMessage}
            </span>
            <span hidden={!props.isLoading}>
              <LoadingIndicator/>
            </span>
          </td>
        </tr>
      </tfoot>
    </table>
  );
}
