"use client";
import React, { HTMLInputTypeAttribute, LegacyRef, useState } from "react";

type Props = {
  inputId: string,
  inputLabel?: any,
  inputPlaceholder?: string,
  inputType?: HTMLInputTypeAttribute | undefined | "textarea",
  required?: boolean,
  minLength?: number,
  maxLength?: number,
  value?: any,
  hidden?: boolean,
  list?: string,
  min?: number,
  max?: number,
  pattern?: string,
  reference?: LegacyRef<HTMLInputElement>,
  onChange?: (event: React.ChangeEvent<any>) => void,
  onBlur?: (event: React.FocusEvent<any>) => void,
  onFocus?: (event: React.FocusEvent<any>) => void,
  onKeyDown?: (event: React.KeyboardEvent<any>) => void,
};

export default function FormGroupInput(props: Readonly<Props>) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // Create a state variable for the error message

  const hidden = props.hidden || props.inputType === "hidden";
  return (
    <div className={"form-group"} hidden={hidden}>
      <label htmlFor={props.inputId} className={"form-label"} hidden={!props.inputLabel}>
        {props.inputLabel}
      </label>
      {props.inputType === "textarea" && (
        <textarea
          className="form-control"
          id={props.inputId}
          name={props.inputId}
          rows={8}
          placeholder={props.inputPlaceholder || ""}
          required={props.required}
          minLength={props.minLength}
          maxLength={props.maxLength}
          defaultValue={props.value}
          onChange={props.onChange}
          onBlur={props.onBlur}
        />
      )}
      {props.inputType !== "textarea" && (
        <input
          onKeyDown={props.onKeyDown}
          onFocus={props.onFocus}
          min={props.min}
          max={props.max}
          onBlur={props.onBlur}
          list={props.list}
          type={props.inputType || "text"}
          className="form-control"
          name={props.inputId}
          id={props.inputId}
          pattern={props.pattern}
          placeholder={props.inputPlaceholder || ""}
          required={props.required}
          minLength={props.minLength}
          maxLength={props.maxLength}
          defaultValue={props.value}
          ref={props.reference}
          onChange={props.onChange}
        />
      )}
    </div>
  );
}
