import useCurrentOrganizationSelection from "@/components/global/hooks/useCurrentOrganizationSelection";
import { useEffect, useState } from "react";
import { CompetitionListItem } from "../../common/CompetitionOverviewTable/CompetitionListItem";

type Props = {
  checkRightToCreateCompetitionFunction: (
    organizationId: string
  ) => Promise<boolean>;
};
export default function useCompetitionOverviewPageController(
  props: Readonly<Props>
) {
  const [competitions, setCompetitions] = useState<CompetitionListItem[]>([]);
  const { selectedOrganizationId } = useCurrentOrganizationSelection();
  const [isCreateCompetitionEnabled, setCreateCompetitionEnabled] =
    useState<boolean>(false);
  const [isLoadingIndicatorVisible, setLoadingIndicatorVisible] = useState<boolean>(true);

  useEffect(() => {
    refreshOrganizationRights();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganizationId]);

  async function refreshOrganizationRights() {
    if (selectedOrganizationId) {
      const isCreateCompetitionEnabled =
        await props.checkRightToCreateCompetitionFunction(
          selectedOrganizationId
        );
      setCreateCompetitionEnabled(isCreateCompetitionEnabled);
    } else {
      setCreateCompetitionEnabled(false);
    }
  }

  const onStartSearching = () => {
    setCompetitions([]);
    setLoadingIndicatorVisible(true);
  };

  const onSearchResultReceived = (result: CompetitionListItem[]) => {
    setCompetitions(result);
    setLoadingIndicatorVisible(false);
  };

  return {
    isCreateCompetitionEnabled,
    onStartSearching,
    onSearchResultReceived,
    isLoadingIndicatorVisible,
    competitions
  };
}
